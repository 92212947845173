import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    useIonViewDidLeave, useIonViewWillEnter
} from '@ionic/react';
import React, {memo, useEffect, useState} from "react";
import {isPlatform} from '@ionic/react';
import IWHVAC from "../client/IWHVAC";
import IWBrokerClient from "../client/IWBrokerClient";
import styles from "./HVACGraphs.module.css";

const HVAC: React.FC = () => {
    const [groups, setGroups] = useState<Array<any>>([]);
    const [nonce, setNonce] = useState<any>(Date.now());
    const [period, setPeriod] = useState<string | undefined>('1d');

    let refreshInterval: any = null;

    function refresh() {
        setNonce(Date.now());
    }

    useIonViewWillEnter(() => {
        console.log('Starting HVAC Graph Refresh');
        refresh();
        refreshInterval = setInterval(refresh, 60 * 1000);
    });

    useIonViewDidLeave(() => {
        console.log('Stopping HVAC Graph Refresh')
        clearInterval(refreshInterval);
    });

    useEffect(() => {
        (async () => {
            IWHVAC.groups$.subscribe(groups => {
                setGroups(Object.values(groups));
            });

            await IWHVAC.requestGroupsIfNeeded();
        })();
    }, []);

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/control/hvac"/>
                    </IonButtons>
                    <IonTitle>HVAC Graphs</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">HVAC Graphs</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <img src={IWBrokerClient.getWeatherGraphURL(period, nonce)} alt="Weather" className={styles.graph}/>
                {groups.map((group, index) => (
                    <img key={group.id} src={IWBrokerClient.getHVACGraphURL(group.id, period, nonce)} alt={group.name}
                         className={styles.graph}/>
                ))}
            </IonContent>
            <IonToolbar>
                <IonSegment value={period} onIonChange={e => setPeriod(e.detail.value)}>
                    <IonSegmentButton value="1d">
                        24h
                    </IonSegmentButton>
                    <IonSegmentButton value="2d">
                        48h
                    </IonSegmentButton>
                    <IonSegmentButton value="7d">
                        7d
                    </IonSegmentButton>
                    <IonSegmentButton value="30d">
                        30d
                    </IonSegmentButton>
                    <IonSegmentButton value="1y">
                        1y
                    </IonSegmentButton>
                </IonSegment>
            </IonToolbar>
        </IonPage>
    );
};

export default memo(HVAC);
